<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.md-auth{
  margin: .4rem 0;
  padding: .36rem .3rem .4rem;
  .title{
    text-align: center;
  }
  .form{
    display: flex; flex-wrap: wrap; justify-content: space-between;
    margin-top: .24rem;
    &-item{
      display: flex; align-items: center;
      margin-bottom: .3rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      width: 100%;
      &--50{
        width: calc(750 / 1540 * 100%);
      }
      &.full{
        margin-right: 40%;
      }
      &_label{
        display: block;
        width: 1rem;
        text-align: right;
        position: relative;
        margin-right: .2rem;
        min-height: .5rem;
        display: flex; align-items: center; justify-content: flex-end;
        line-height: 1.4em;
        &.required {
          .p_label{
            position: relative;
            &:after{
              content: '*';
              color: #f53e39;
              position: absolute; right: -.5em; top: .02rem;
            }
          }
          .p_tips{
            text-align: center;
          }
        }
      }
      &_content{
        flex: 1;
      }
      &--idcard{
        display: block;
        .form-item_content{
          display: flex; justify-content: space-between;
          .c-id-card{
            width: 7.5rem;
          }
        }
      }
      &--agreement{
        justify-content: center; align-items: center;
        margin: .06rem 0 .24rem;
        a{
          color: $blue;
        }
        .c-radio{
          margin-right: .06rem;
        }
      }
      &--submit{
        justify-content: center;
        .base-button{
          width: 1.5rem;
        }
      }
    }
    .ipt{
      width: 100%; height: .5rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .14rem;
      transition: .3s;
      &::placeholder{
        color: #999;
      }
      &:focus{
        border-color: $blue;
      }
    }
    .ipt-bank{
      display: flex; align-items: center;
      .ipt{
        width: 1.9rem;
        &:first-of-type{
          margin-right: .28rem;
        }
      }
      .split{
        width: .28rem;
        text-align: center;
      }
    }
  }
}
</style>